/**
 * Created by amine on 22/03/2017. // edited 28/01/2020
 */

(function () {
    'use strict';


    class PregnancyTermCtrl {
        constructor(pregnancyService, configService, $element) {
            this.pregnancyService = pregnancyService;
            this.configService = configService;
            this.element = $element;

            this.pregnancy = this.pregnancy || null;
            this.calculated = this.calculated || false;

            this.element.addClass("flex-nogrow layout-row");
        }

        static get $inject() {
            return ["pregnancyService", "configService", "$element"];
        }

        $onInit() {
            this.configService.getByHttp("pregnancy_block")
                .then(config => {
                    this.blockConfig = config;
                    if (!_.isNil(this.pregnancy) && !this.calculated)
                        this.pregnancy = this.pregnancyService.calculateFields(this.pregnancy, this.blockConfig);
                }, _.noop);

        }

        $onChanges(changes) {
            if (_.get(changes, "pregnancy.currentValue")) {
                if (!_.isNil(this.pregnancy)) {
                    this.element.show();
                    if (!this.calculated) this.pregnancyService.calculateFields(this.pregnancy, this.blockConfig);
                } else {
                    this.element.hide();
                }
            }
        }
    }

    module.exports = {
        bindings: {
            pregnancy: '<',
            calculated: '<?calculated',
        },
        template: require("specifics/views/pregnancy-term.tpl.html"),
        controllerAs: "vm",
        controller: PregnancyTermCtrl,
    };


})();